//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'GroupDashboard',
  methods: {
    switchState(state) {
      this.$emit('switch', state);
    }
  },
  data() {
    return {
      tab: 'posts'
    }
  }
}
